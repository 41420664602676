<template>
  <div class="testReport_pages2 A4height">
    <div class="testReport_pages2_top">
      <div class="testReport_pages2_top_text1">
        尊敬的艾健康女士您好!
      </div>
      <div class="testReport_pages2_top_text2">
        非常感谢您的信任，针对您目前的健康状况，为您制订了健康管理整体解决方案，如下：
      </div>
    </div>
    <div class="testReport_pages2_info1">
      <div class="testReport_pages2_info1_title">
        一、目前您的主要健康隐患：
      </div>
      <div class="testReport_pages2_info1_ul">
        <div class="testReport_pages2_info1_li">
          <div class="testReport_pages2_info1_li_left">
            脂肪肝
          </div>
          <div class="testReport_pages2_info1_li_right">
            <img class="imgs" src="" alt="">
          </div>
        </div>
        <div class="testReport_pages2_info1_li">
          <div class="testReport_pages2_info1_li_left">
            肠道
          </div>
          <div class="testReport_pages2_info1_li_right">
            <img class="imgs" src="" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="testReport_pages2_info2">
      <div class="testReport_pages2_info2_title">
        二、影响您以上健康隐患的相关失衡因素分析：
      </div>
      <div class="testReport_pages2_info2_ul">
        <div class="testReport_pages2_info2_li">
          <div class="testReport_pages2_info2_li_left">
            <img class="imgs" src="" alt="">
          </div>
          <div class="testReport_pages2_info2_li_text">
            慢性炎症
          </div>
        </div>
        <div class="testReport_pages2_info2_li">
          <div class="testReport_pages2_info2_li_left">
            <img class="imgs" src="" alt="">
          </div>
          <div class="testReport_pages2_info2_li_text">
            慢性炎症
          </div>
        </div>
        <div class="testReport_pages2_info2_li">
          <div class="testReport_pages2_info2_li_left">
            <img class="imgs" src="" alt="">
          </div>
          <div class="testReport_pages2_info2_li_text">
            慢性炎症
          </div>
        </div>
        <div class="testReport_pages2_info2_li">
          <div class="testReport_pages2_info2_li_left">
            <img class="imgs" src="" alt="">
          </div>
          <div class="testReport_pages2_info2_li_text">
            慢性炎症
          </div>
        </div>
        <div class="testReport_pages2_info2_li">
          <div class="testReport_pages2_info2_li_left">
            <img class="imgs" src="" alt="">
          </div>
          <div class="testReport_pages2_info2_li_text">
            慢性炎症
          </div>
        </div>

      </div>
    </div>
    <div class="testReport_pages2_info3">
      <div class="testReport_pages2_info3_title">
        三、影响您健康隐患的生活方式因素分析:
      </div>
      <div class="testReport_pages2_info3_ul">
        <div class="testReport_pages2_info3_li a_flex">
          饮食不均衡
        </div>
        <div class="testReport_pages2_info3_li a_flex">
          饮食
        </div>
        <div class="testReport_pages2_info3_li a_flex">
          饮食不均衡
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
}
</script>

<style lang="scss">
.testReport_pages2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  padding: 0 48px;
  background-color: #fff;

  .testReport_pages2_top {
    line-height: 28px;

    .testReport_pages2_top_text1 {
      color: #20A7B2;
      font-size: 24px;
      ;
    }

    .testReport_pages2_top_text2 {
      color: #474F57;
      font-size: 12px;
    }
  }

  .testReport_pages2_info1 {
    margin-top: 44px;

    .testReport_pages2_info1_title {
      font-size: 21px;
      font-weight: 500;
      color: #474F57;
    }

    .testReport_pages2_info1_ul {
      display: flex;
      flex-wrap: wrap;
      margin-top: 31px;

      .testReport_pages2_info1_li {
        width: 109px;
        height: 39px;
        margin-right: 30px;
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #F4F4F4;
        border-radius: 10px;

        .testReport_pages2_info1_li_left {
          font-size: 16px;
          color: #474F57;
          padding-left: 13px;
        }

        .testReport_pages2_info1_li_right {
          width: 22px;
          height: 22px;
          margin-right: 8px;

          .imgs {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  .testReport_pages2_info2 {
    margin-top: 70px;

    .testReport_pages2_info2_title {
      font-size: 21px;
      font-weight: 500;
      color: #474F57;
    }

    .testReport_pages2_info2_ul {
      // width: 70%;
      margin-top: 24px;
      display: flex;
      flex-wrap: wrap;
      .testReport_pages2_info2_li {
        width: 139px;
        height: 49px;
        display: flex;
        align-items: center;
        margin-right: 52px;
        margin-bottom: 21px;
        background-color: #F4F4F4;
        border-radius: 10px;
        .testReport_pages2_info2_li_left {
          width: 22px;
          height: 22px;
          margin-left: 10px;
          .imgs {
            width: 100%;
            height: 100%;
          }
        }
        .testReport_pages2_info2_li_text {
          font-size: 16px;
          font-family: Source Han Sans SC;
          font-weight: 400;
          padding-left: 10px;
          color: #474F57;
        }
      }
    }
  }

  .testReport_pages2_info3 {
    margin-top: 70px;

    .testReport_pages2_info3_title {
      font-size: 21px;
      font-weight: 500;
      color: #474F57;
    }

    .testReport_pages2_info3_ul {
      margin-top: 25px;

      .testReport_pages2_info3_li {
        display: inline-flex;
        margin-bottom: 20px;
        margin-right: 22px;
        padding: 8px 6px;
        background: #DECA63;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 400;
        color: #fff;
      }
    }
  }
}
</style>
