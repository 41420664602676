<template>
  <div class="testReport_pages15 A4height">
    <Head bgColor="#c5ead2"></Head>
    <div class="testReport_pages4_title_box">
      <div class="testReport_pages4_title_box_serialNumber">
        05
      </div>
      <div class="testReport_pages4_title_box_title">
        <div class="testReport_pages4_title_box_title_p">睡眠</div>
        <div class="testReport_pages4_title_box_title_d">的并发症</div>
      </div>
    </div>
    <div class="testReport_pages15_content">
      <div class="testReport_pages15_content_title">
        NAFLD 主要并发症是肝硬化这是肝脏后期的癫痕形成(纤维化)造成的
      </div>
      <div class="testReport_pages15_content_box clearfix">
        <div class="testReport_pages15_content_box_img">
          <div class="testReport_pages15_content_box_img_box wh100 clearfix">
            <img class="wh100" style="position: absolute;" src="https://image.giantgocloud.com/www//ImageMapping/image/20231226/FA86533F5AD94530A0C9F2D5428BC451.png" alt="" />
            <div class="testReport_pages15_content_box_img_box_text_box">
              <div class="testReport_pages15_content_box_img_box_text_title">
                肝硬化可导致:
              </div>
              <div class="testReport_pages15_content_box_img_box_text_title_ul">
                <div class="testReport_pages15_content_box_img_box_text_title_li">
                  腹部积液(肝腹水)
                </div>
                <div class="testReport_pages15_content_box_img_box_text_title_li">
                  食管静脉肿胀,可能会破裂并出血
                </div>
                <div class="testReport_pages15_content_box_img_box_text_title_li">
                   思维混乱、嗜睡和语言不清(肝性脑病
                </div>
                <div class="testReport_pages15_content_box_img_box_text_title_li">
                  肝癌
                </div>
                <div class="testReport_pages15_content_box_img_box_text_title_li">
                  腹部积液(肝腹水)
                </div>
                <div class="testReport_pages15_content_box_img_box_text_title_li">
                  食管静脉肿胀,可能会破裂并出血
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="testReport_pages15_bottom">
        终未期肝衰竭，这意味着肝脏已停止运作。大约20% 的非酒精
        性脂肪性肝炎患者会发展为肝硬化。
      </div>
    </div>
  </div>
</template>

<script>
  import Head from "./head.vue"
  export default {
    data(){
      return{
        listData:[
          {
            type:0,
            text:'易疲劳'
          },
          {
            type:1,
            text:'肝肿大'
          },
          {
            type:2,
            text:'皮肤发黄'
          },
          {
            type:3,
            text:'皮肤发黄'
          },
          {
            type:4,
            text:'皮肤发黄'
          },
          {
            type:5,
            text:'肝肿大'
          },
          {
            type:6,
            text:'肝肿大'
          },
          {
            type:7,
            text:'皮肤发黄'
          },
          {
           type:8,
           text:'肝肿大'
          },
          {
           type:9,
           text:'皮肤发黄'
          },
        ]
      }
    },
    methods:{
hexToRgb(hex, opt) {
                // Remove "#" symbol from the beginning of the string
                hex = hex.replace("#", "");
                // Convert each pair of characters to decimal values for red, green, and blue
                var red = parseInt(hex[0] + hex[1], 16);
                var green = parseInt(hex[2] + hex[3], 16);
                var blue = parseInt(hex[4] + hex[5], 16);
                return 'rgba(' + red + ',' + green + ',' + blue + ',' + opt + ')';
            }
    },
    mounted() {

    },
    components:{
      Head,
    }
  }
</script>

<style lang="scss" scoped>
  .testReport_pages15{
    background-color: #fff;
    .testReport_pages4_title_box {
    position: relative;
    width: 90px;
    height: 140px;
    border: 3px solid #DFE3E8;
    margin-top: 48px;
    margin-left: 50px;

    .testReport_pages4_title_box_serialNumber {
      font-size: 55px;
      font-weight: 400;
      color: #DFE3E8;
      padding-top: 70px;
      padding-left: 10px;
    }

    .testReport_pages4_title_box_title {
      position: absolute;
      left: 20px;
      top: 14px;
      width: 200px;
      background-color: #fff;
      color: #7DC580;
      font-size: 24px;
    }
  }
    .testReport_pages15_content{
      margin-top: 34px;
      .testReport_pages15_content_title{
        margin-left:69px;
        font-size: 12px;
        font-weight: 400;
        color: #474F57;
        line-height: 30px;
      }
      .testReport_pages15_content_box{
        position: relative;
        margin-top: 28px;
        .testReport_pages15_content_box_img{
          width:535px;
          height:455px;
          position: absolute;
          right: 0;
          .testReport_pages15_content_box_img_box{
            position: relative;
            .testReport_pages15_content_box_img_box_text_box{
              position: relative;
              z-index:1;
              margin-top: 223px;
              margin-left: 193px;
              .testReport_pages15_content_box_img_box_text_title{
                font-size: 21px;
                font-weight: 500;
                color: #FFFFFF;
              }
              .testReport_pages15_content_box_img_box_text_title_ul{
                margin-top: 28px;
                .testReport_pages15_content_box_img_box_text_title_li{
                  font-size: 14px;
                  font-weight: 400;
                  color: #FFFFFF;
                  margin-bottom: 5px;
                }
              }
            }
          }
        }

      }
      .testReport_pages15_bottom{
        width:318px;
        margin-left: 70px;
        margin-top: 490px;
        font-size: 12px;
        font-weight: 400;
        color: #474F57;
        line-height: 24px;
      }
    }
  }

</style>
