<template>
  <div class="testReport">
    <Home/>
    <Pages2/>
    <Pages4 />
    <Pages6 />
    <Pages7 />
    <Pages9 />
    <Pages3/>
    <Pages17/>
    <Pages18/>
    <Pages19/>
    <Pages11/>
    <Pages12/>
    <Pages13/>
    <Pages20/>
    <Pages15/>
    <!-- <Pages8 /> -->
  </div>
</template>

<script>
import H5Report from '@/api/index'
// import Head from "./funReport/head.vue"
import Home from './funReport/home.vue'
// import Pages1 from "./funReport/pages1.vue"
import Pages2 from './funReport/pages2.vue'
import Pages3 from './funReport/pages3.vue'
import Pages4 from './funReport/pages4.vue'
import Pages6 from './funReport/pages6.vue'
import Pages7 from './funReport/pages7.vue'
import Pages8 from './funReport/pages8.vue'
import Pages9 from './funReport/pages9.vue'
import Pages11 from './funReport/pages11.vue'
import Pages12 from './funReport/pages12.vue'
import Pages13 from './funReport/pages13.vue'
import Pages14 from './funReport/pages14.vue'
import Pages15 from './funReport/pages15.vue'
import Pages17 from './funReport/pages17.vue'
import Pages18 from './funReport/pages18.vue'
import Pages19 from './funReport/pages19.vue'
import Pages20 from './funReport/pages20.vue'
import axios from 'axios'
export default {
  data () {
    return {

    }
  },
  components: {
    Home,
    // Head,
    // Pages1,
    Pages2,
    Pages3,
    Pages4,
    Pages6,
    Pages7,
    Pages8,
    Pages9,
    Pages11,
    Pages12,
    Pages13,
    Pages14,
    Pages15,
    Pages17,
    Pages18,
    Pages19,
    Pages20
  },
  mounted () {
    const that = this
    const id = this.$query('id')
    axios
      .post(
        // `https://healthingcoretest.giantgocloud.com/api/SceneCheckPage/GetPrecisePdfPageData`,
        `${H5Report}/api/SceneCheckPage/GetPrecisePdfPageData`,
        {
          id: id
        }
      ).then(res => {
        const data = res.data.data
        console.log(data, 'data')
      })
  }
}
</script>

<style lang='scss' scoped>
.testReport {
  width: 595px;
}
</style>
