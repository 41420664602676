<template>
    <div class="testReport_pages3">
       <div class="testReport_pages3_d">
            <div class="line"></div>
            <div class="title">睡眠</div>
            <div class="report_type">功能分析报告</div>
       </div>
       <div class="testReport_pages3_r">
        <div>ANALYSIS</div>
        <div> HEALTH REPORT</div>
       </div>
    </div>
</template>

<script>
export default {
  data () {
    return {

    }
  }
}
</script>

<style lang="scss">
.testReport_pages3 {
    width: 595px;
    height: 842px;
    background: url('https://image.giantgocloud.com/www/ImageMapping/image/20231228/E6C8687933054E7AB39EC1CC036CB971.png') no-repeat;
    background-size: 100% 100%;
    position: relative;
    .testReport_pages3_d{
        padding-top: 420px;
        padding-left: 60px;
        color: #20A7B2;
        font-size: 24px;
        font-weight: 600;
       
        .line{
            width: 100px;
            height: 2px;
            background-color: #20A7B2;
            margin-bottom: 10px;
        }
        .title{
            padding: 5px 0;
        }
    }
    .testReport_pages3_r{
        padding-top: 200px;
        padding-left: 60px;
        color: #20A7B2;
        font-size: 14px;
    }
   
}
</style>
