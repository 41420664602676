<template>
  <div class="testReport_pages15 A4height">

    <Head bgColor="#BCEDEC"></Head>
    <div class="testReport_pages4_title_box">
      <div class="testReport_pages4_title_box_serialNumber">
        02
      </div>
      <div class="testReport_pages4_title_box_title">
        <div class="testReport_pages4_title_box_title_p">影响你胃健康</div>
        <div class="testReport_pages4_title_box_title_d"> 相关器官/因素分析</div>
      </div>
    </div>
    <div class="contents">
      <div class="left">
        <div class="left_title">
          <div class="left_title_img">
            <img class="imgs" src="" alt="">
          </div>
          <div class="left_title_pross">
            <div class="left_title_pross_text">
              胃
            </div>
            <div class="left_title_pross_w"></div>
          </div>
        </div>
        <div class="left_title_text">
          肝脏是体内主要的代谢器官之一，负责脂肪、碳水化合物和蛋白质的代谢。如果肝脏代谢功能下降，就可能导致脂肪在肝细胞内的积累，引发脂
        </div>
        <div class="left_title">
          <div class="left_title_img">
            <img class="imgs" src="" alt="">
          </div>
          <div class="left_title_pross">
            <div class="left_title_pross_text">
              肠道
            </div>
            <div class="left_title_pross_w"></div>
          </div>
        </div>
        <div class="left_title_text">
          肠道的功能异常可能影响食物的消化和吸收，导致营养代谢紊乱，增加脂肪肝的风险
        </div>
      </div>
      <div class="rgight">
        <img class="imgs"
          src="https://image.giantgocloud.com/www//ImageMapping/image/20231226/956001B07CC24310B3C08E15E1FC6B09.png"
          alt="">
      </div>
    </div>
  </div>
</template>

<script>
import Head from "./head.vue"
export default {
  data() {
    return {

    }
  },
  methods: {

  },
  mounted() {

  },
  components: {
    Head,
  }
}
</script>

<style lang="scss" scoped>
.testReport_pages15 {
  background-color: #fff;
  width: 595px;
    height: 842px;
    // padding: 0 60px 0 53px;
    position: relative;
  .testReport_pages4_title_box {
    position: relative;
    width: 90px;
    height: 140px;
    border: 3px solid #DFE3E8;
    margin-top: 48px;
    margin-left: 50px;

    .testReport_pages4_title_box_serialNumber {
      font-size: 55px;
      font-weight: 400;
      color: #DFE3E8;
      padding-top: 70px;
      padding-left: 10px;
    }

    .testReport_pages4_title_box_title {
      position: absolute;
      left: 20px;
      top: 14px;
      width: 200px;
      background-color: #fff;
      color: #2BD8D8;
      font-size: 24px;
    }
  }

  .contents {
    display: flex;
    justify-content: space-between;
    padding-top: 60px;

    .left {
      flex: 1;
      margin-left: 60px;
      .left_title {
        display: flex;
        align-items: center;
        margin-top: 20px;
        .left_title_img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          border: 2px solid #5EC692;

          .imgs {
            width: 100%;
            height: 100%;
          }
        }

        .left_title_pross {
          .left_title_pross_text {
            font-size: 14px;
            color: #474F57;
            padding-left: 8px;
          }

          .left_title_pross_w {
            background-color: #5EC692;
            width: 100px;
            height: 24px;
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
          }
        }
      }

      .left_title_text {
        color: #474F57;
        font-size: 12px;
        padding-left: 30px;
        padding-top: 10px;
      }
    }

    .rgight {
      width: 208px;
      min-height: 400px;

      .imgs {
        width: 100%;
        height: 100%;
      }
    }
  }
}</style>
