<template>
    <div class="testReport_pages6">

        <Head :bgColor="`#C8EBED`"></Head>
        <div class="testReport_pages4_title_box">
            <div class="testReport_pages4_title_box_serialNumber">
                02
            </div>
            <div class="testReport_pages4_title_box_title">
                <div class="testReport_pages4_title_box_title_p">饮食与</div>
                <div class="testReport_pages4_title_box_title_d">生活方式与建议</div>
            </div>
        </div>
        <div class="testReport_pages6_contenta">
            <div class="triangle"><img
                    src="https://image.giantgocloud.com/www//ImageMapping/image/20231226/FD7929D8D3064C23B31A4512A037F165.png"
                    alt=""></div>
            <div class="square">
                <div class="square_list">
                    <div v-for="(item, index) in listdata" :key="index">
                        <div class="square_listTitle">
                            <div class="icon"></div>
                            <div class="title">{{ item.title }}</div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Head from "./head.vue"
export default {
    components: {
        Head,
    },
    data() {
        return {
            listdata: [
                { title: '低饱和脂肪和低胆固醇饮食' },
                { title: '食用富含高纤维的食物，如蔬菜、全谷类食物等' },
                { title: '适度饮酒或不饮酒' },
                { title: '戒烟' },
                { title: '坚持运动锻炼' },
                { title: '保持健康体重' },
                { title: '管控妥有关的慢性疾病/健康状况' }
            ]
        }
    }
}
</script>

<style lang="scss">
.testReport_pages6 {
    width: 595px;
    height: 842px;
    padding: 0 60px 0 53px;
    background: url('https://image.giantgocloud.com/www/ImageMapping/image/20231228/FE279657DB814B38ADB294313ECCEE64.png') no-repeat;
    background-size: 100% 100%;

    .testReport_pages4_title_box {
        position: relative;
        width: 90px;
        height: 140px;
        border: 3px solid #DFE3E8;
        margin-top: 48px;
        margin-left: 50px;

        .testReport_pages4_title_box_serialNumber {
            font-size: 55px;
            font-weight: 400;
            color: #DFE3E8;
            padding-top: 70px;
            padding-left: 10px;
        }

        .testReport_pages4_title_box_title {
            position: absolute;
            left: 20px;
            top: 14px;
            width: 200px;
            background-color: #fff;
            color: #20A7B2;
            font-size: 24px;
        }
    }

    .testReport_pages6_contenta {
        margin-top: 22px;

        .triangle {
            width: 70px;
            height: 47px;
            margin-left: 90px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .square {
            width: 492px;
            height: 319px;
            background: #63CCCE;
            border-radius: 38px;
            padding: 46px 54px 121px 54px;

            .square_list {
                .square_listTitle {
                    display: flex;
                    align-items: center;
                    margin: 10px 0;

                    .icon {
                        width: 7px;
                        height: 7px;
                        border: 1px solid #FFFFFF;
                        border-radius: 50%;
                    }

                    .title {
                        font-size: 12px;
                        font-family: Source Han Sans SC;
                        font-weight: 400;
                        color: #FFFFFF;
                        margin-left: 5px;
                    }
                }
            }
        }
    }
}</style>
