<template>
    <div class="testReport_pages9">
        <div class="testReport_pages4_title_box">
            <div class="testReport_pages4_title_box_serialNumber">
                05
            </div>
            <div class="testReport_pages4_title_box_title">
                <!-- <div class="testReport_pages4_title_box_title_p">饮食与</div> -->
                <div class="testReport_pages4_title_box_title_d">食疗建议</div>
            </div>
        </div>
        <div class="content9">
            <div class="content9_title">
                五行蔬菜汤
            </div>

            <div class="content9_img">
                <img src="" alt="">
            </div>
            <div>
                <div class="content9_contnet">
                    <div class="content9_contnet1">
                        <div class="content9_contnet1_title">材料</div>
                        <div class="content9_contnet1_line"></div>

                    </div>
                    <div class="content9_contnet2">胡萝卜1/2条、白萝卜1/4条、白萝卜叶约200g、牛莠1/2条、香菇2朵（要经阳光暴晒2 天以上）</div>
                </div>
            </div>
            <div>
                <div class="content9_contnet">
                    <div class="content9_contnet1">
                        <div class="content9_contnet1_title">材料</div>
                        <div class="content9_contnet1_line"></div>

                    </div>
                    <div class="content9_contnet2">胡萝卜1/2条、白萝卜1/4条、白萝卜叶约200g、牛莠1/2条、香菇2朵（要经阳光暴晒2 天以上）</div>
                </div>
            </div>
            <div>
                <div class="content9_contnet">
                    <div class="content9_contnet1">
                        <div class="content9_contnet1_title">材料</div>
                        <div class="content9_contnet1_line"></div>

                    </div>
                    <div class="content9_contnet2">胡萝卜1/2条、白萝卜1/4条、白萝卜叶约200g、牛莠1/2条、香菇2朵（要经阳光暴晒2 天以上）</div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
  data () {
    return {

    }
  }
}
</script>

<style lang="scss">
.testReport_pages9 {
    width: 595px;
    height: 842px;
    padding: 0 60px 0 53px;
    position: relative;

    .testReport_pages4_title_box {
        position: relative;
        width: 90px;
        height: 140px;
        border: 3px solid #DFE3E8;
        margin-top: 48px;
        margin-left: 0px;

        .testReport_pages4_title_box_serialNumber {
            font-size: 55px;
            font-weight: 400;
            color: #DFE3E8;
            padding-top: 70px;
            padding-left: 10px;
        }

        .testReport_pages4_title_box_title {
            position: absolute;
            left: 20px;
            top: 28px;
            width: 200px;
            background-color: #fff;
            color: #20A7B2;
            font-size: 24px;
        }
    }
}

.content9 {
    .content9_title {
        width: 141px;
        height: 31px;
        background: #D0E7EF;
        border-radius: 0px 15px 15px 0px;
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #4A9AAF;
        text-align: center;
        line-height: 31px;
        margin-top: 40px;
    }

    .content9_img {
        width: 456px;
        height: 237px;
        margin-top: 20px;

        img {
            width: 100%;
            height: 100%;
        }
    }
}

.content9_contnet {
    .content9_contnet1 {
        margin-top: 20px;

        .content9_contnet1_title {
            font-size: 14px;
            font-family: Source Han Sans SC;
            font-weight: 400;
            color: #4A9AAF;
        }

        .content9_contnet1_line {
            width: 31px;
            height: 1px;
            border: 1px solid #4A9AAF;
        }
    }
}
</style>
