<template>
    <div class="testReport_pages7">

        <Head :bgColor="`#C8EBED`"></Head>
        <div class="testReport_pages4_title_box">
            <div class="testReport_pages4_title_box_serialNumber">
                03
            </div>
            <div class="testReport_pages4_title_box_title">
                <!-- <div class="testReport_pages4_title_box_title_p">饮食与</div> -->
                <div class="testReport_pages4_title_box_title_d">营养建议</div>
            </div>
        </div>
        <div class="testReport_pages6_contenta">
            <div class="testReport_pages6_contenta1">
                以下是基于循证医学和循证营养学有关文献综合的结果。 有助于防控甘油三酯血症的营养和草本补充剂，主要包括如下：
            </div>
            <div class="testReport_pages6_contenta2">
                <div class="box">
                    <div class="box1_content1">主靶向:</div>
                    <div class="box1">
                        <div class="box1_content2">
                            <div class="box1_content2_title">
                                <div class="box1_content2_title1">
                                    1 血脂营养干预——血糖助推器
                                </div>
                                <div class="box1_content2_title1_line">

                                </div>
                                <div class="box1_content2_title1_cil">

                                </div>
                            </div>
                            <div class="box1_content3">
                                <div class="square_listTitle">
                                    <div class="icon1"></div>
                                    <div class="title">降低甘油三酯水平</div>
                                </div>
                                <div class="square_listTitle">
                                    <div class="icon1"></div>
                                    <div class="title">降低甘油三酯水平</div>
                                </div>
                            </div>
                        </div>
                        <div class="box1_content2">
                            <div class="box1_content2_title">
                                <div class="box1_content2_title1">
                                    2 血脂营养干预——血糖助推器
                                </div>
                                <div class="box1_content2_title1_line">

                                </div>
                                <div class="box1_content2_title1_cil">

                                </div>
                            </div>
                            <div class="box1_content3">
                                <div class="square_listTitle">
                                    <div class="icon1"></div>
                                    <div class="title">降低甘油三酯水平</div>
                                </div>
                                <div class="square_listTitle">
                                    <div class="icon1"></div>
                                    <div class="title">降低甘油三酯水平</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="box">
                    <div class="box1_content1">主靶向:</div>
                    <div class="box1">
                        <div class="box1_content2">
                            <div class="box1_content2_title">
                                <div class="box1_content2_title1">
                                    1 血脂营养干预——血糖助推器
                                </div>
                                <div class="box1_content2_title1_line">

                                </div>
                                <div class="box1_content2_title1_cil">

                                </div>
                            </div>
                            <div class="box1_content3">
                                <div class="square_listTitle">
                                    <div class="icon1"></div>
                                    <div class="title">降低甘油三酯水平</div>
                                </div>
                                <div class="square_listTitle">
                                    <div class="icon1"></div>
                                    <div class="title">降低甘油三酯水平</div>
                                </div>
                            </div>
                        </div>
                        <div class="box1_content2">
                            <div class="box1_content2_title">
                                <div class="box1_content2_title1">
                                    2 血脂营养干预——血糖助推器
                                </div>
                                <div class="box1_content2_title1_line">

                                </div>
                                <div class="box1_content2_title1_cil">

                                </div>
                            </div>
                            <div class="box1_content3">
                                <div class="square_listTitle">
                                    <div class="icon1"></div>
                                    <div class="title">降低甘油三酯水平</div>
                                </div>
                                <div class="square_listTitle">
                                    <div class="icon1"></div>
                                    <div class="title">降低甘油三酯水平</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="testReport_pages6_contentb">
            <img src="https://image.giantgocloud.com/www//ImageMapping/image/20231211/2163359B4AAE4B97BB4C534B794B9F6A.png"
                alt="">
        </div>
    </div>
</template>

<script>
import Head from "./head.vue"
export default {
    components: {
        Head,
    },
    data() {
        return {

        }
    }
}
</script>

<style lang="scss">
.testReport_pages7 {
    width: 595px;
    height: 842px;
    padding: 0 60px 0 53px;
    position: relative;

    .testReport_pages4_title_box {
        position: relative;
        width: 90px;
        height: 140px;
        border: 3px solid #DFE3E8;
        margin-top: 48px;
        margin-left: 0px;

        .testReport_pages4_title_box_serialNumber {
            font-size: 55px;
            font-weight: 400;
            color: #DFE3E8;
            padding-top: 70px;
            padding-left: 10px;
        }

        .testReport_pages4_title_box_title {
            position: absolute;
            left: 20px;
            top: 28px;
            width: 200px;
            background-color: #fff;
            color: #20A7B2;
            font-size: 24px;
        }
    }

    .box1_content3 {
        margin-top: 15px;

        .square_listTitle {
            display: flex;
            align-items: center;
            font-size: 10px;
            color: #000;
            margin: 5px 0;

            .icon1 {
                width: 8px;
                height: 8px;
                border: 1px solid #000;
                border-radius: 50%;
            }

            .title {
                margin-left: 3px;
            }
        }
    }

    .testReport_pages6_contenta {
        margin-top: 20px;
        width: 368px;

        .testReport_pages6_contenta1 {
            width: 304px;
            font-size: 12px;
            font-family: Source Han Sans SC;
            font-weight: 400;
            color: #474F57;
            line-height: 18px;
        }

        .testReport_pages6_contenta2 {
            font-size: 14px;
            font-weight: 500;
            color: #4A9AAF;

            .box {
                .box1_content1 {
                    font-size: 14px;
                    font-weight: 600;
                    margin: 47px 0 15px 0;
                }

                .box1 {
                    .box1_content2 {
                        .box1_content2_title {
                            display: flex;
                            align-items: center;

                            .box1_content2_title1 {}

                            .box1_content2_title1_line {
                                width: 159px;
                                height: 1px;
                                background-color: #4A9AAF;
                                flex: 1;
                            }

                            .box1_content2_title1_cil {
                                width: 5px;
                                height: 5px;
                                background: #4A9AAF;
                                border-radius: 50%;
                            }
                        }
                    }
                }
            }
        }
    }

    .testReport_pages6_contentb {
        width: 227px;
        height: 444px;
        position: absolute;
        right: 0;
        top: 300px;

        img {
            width: 100%;
            height: 100%;
        }
    }
}</style>
