<template>
    <div class="testReport_pages8">
        <Head :bgColor="`#C8EBED`"></Head>
        <div class="testReport_pages4_title_box">
            <div class="testReport_pages4_title_box_serialNumber">
                04
            </div>
            <div class="testReport_pages4_title_box_title">
                <!-- <div class="testReport_pages4_title_box_title_p">饮食与</div> -->
                <div class="testReport_pages4_title_box_title_d">医疗干预</div>
            </div>
        </div>
        <div class="content8">
            <div class="content8_box">
                <div class="content8_title1">
                    通常用于降低高甘油三脂药物如下：
                </div>
                <div class="content8_title2">
                    <!-- <div class="content8_small_title">
                        <div class="content8_small_title_cil"></div>
                        <div class="content8_small_title_tit">贝特类调脂药</div>
                    </div> -->
                    <div class="content8_small_content">
                        非诺贝特、苯扎贝特和吉非罗齐等，通过降低肝脏极低密度脂蛋白(VLDL)胆固醇的产量和非诺贝特、苯扎贝特和吉非罗齐等，通过降低肝脏极低密度脂蛋白(VLDL)胆固醇的产
                    </div>
                </div>
            </div>
            <div class="content8_box">
                <div class="content8_title1">
                    通常用于降低高甘油三脂药物如下：
                </div>
                <div class="content8_title2">
                    <!-- <div class="content8_small_title">
                        <div class="content8_small_title_cil"></div>
                        <div class="content8_small_title_tit">贝特类调脂药</div>
                    </div> -->
                    <div class="content8_small_content">
                        非诺贝特、苯扎贝特和吉非罗齐等，通过降低肝脏极低密度脂蛋白(VLDL)胆固醇的产量和非诺贝特、苯扎贝特和吉非罗齐等，通过降低肝脏极低密度脂蛋白(VLDL)胆固醇的产
                    </div>
                </div>
            </div>
            <div class="content8_box">
                <div class="content8_title1">
                    通常用于降低高甘油三脂药物如下：
                </div>
                <div class="content8_title2">
                    <!-- <div class="content8_small_title">
                        <div class="content8_small_title_cil"></div>
                        <div class="content8_small_title_tit">贝特类调脂药</div>
                    </div> -->
                    <div class="content8_small_content">
                        非诺贝特、苯扎贝特和吉非罗齐等，通过降低肝脏极低密度脂蛋白(VLDL)胆固醇的产量和非诺贝特、苯扎贝特和吉非罗齐等，通过降低肝脏极低密度脂蛋白(VLDL)胆固醇的产
                    </div>
                </div>
            </div>

        </div>
        <div class="references">
            <div class="referencesTitle">参考文献:</div>
            <div class="referencesContent">1. Drenjancevic I et al. Omega-3 Polyunsaturated Fatty Acids-Va1. Drenjancevic I
                et al. Omega-3 Polyunsaturated Fatty Acids-Va1. Drenjancevic I et al. Omega-3 Polyunsaturated Fatty
                Acids-Va1. Drenjancevic I et al. Omega-3 Polyunsaturated Fatty Acids-Va1. Drenjancevic I et al. Omega-3
                Polyunsaturated Fatty Acids-Va</div>
        </div>
    </div>
</template>

<script>
export default {
  data () {
    return {

    }
  }
}
</script>

<style lang="scss">
.testReport_pages8 {
    width: 595px;
    height: 842px;
    padding: 0 60px 0 53px;
    position: relative;

    .testReport_pages4_title_box {
        position: relative;
        width: 90px;
        height: 140px;
        border: 3px solid #DFE3E8;
        margin-top: 48px;
        margin-left: 0px;

        .testReport_pages4_title_box_serialNumber {
            font-size: 55px;
            font-weight: 400;
            color: #DFE3E8;
            padding-top: 70px;
            padding-left: 10px;
        }

        .testReport_pages4_title_box_title {
            position: absolute;
            left: 20px;
            top: 28px;
            width: 200px;
            background-color: #fff;
            color: #20A7B2;
            font-size: 24px;
        }
    }
}

.content8 {
    font-size: 18px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #4A9AAF;
    margin-top: 34px;
    margin-left: 100px;
    .content8_box {
        .content8_title1 {
            font-weight: 600;
            margin-top: 20px;
        }

        .content8_title2 {
            margin-top: 10px;

            .content8_small_title {
                display: flex;
                align-items: center;

                .content8_small_title_cil {
                    width: 8px;
                    height: 8px;
                    border: 1px solid #4A9AAF;
                    border-radius: 50%;
                }

                .content8_small_title_tit {
                    font-size: 18px;
                    font-family: Source Han Sans SC;
                    font-weight: 400;
                    color: #4A9AAF;
                    margin-left: 5px;
                }
            }

            .content8_small_content {
                font-size: 10px;
                font-family: Source Han Sans SC;
                font-weight: 400;
                color: #474F57;
                // margin-left: 15px;
                margin: 15px;
            }
        }
    }

}

.references {
    font-size: 18px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #4A9AAF;
    margin-left: 5px;
    margin-top: 40px;
    .referencesContent {
        font-size: 8px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #474F57;
        margin-top: 30px;
    }

}
</style>
