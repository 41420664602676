<template>
    <div class="testReport_pages9">

        <Head bgColor="#C1EAC1"></Head>
        <div class="testReport_pages4_title_box">
            <div class="testReport_pages4_title_box_serialNumber">
                04
            </div>
            <div class="testReport_pages4_title_box_title">
                <div class="testReport_pages4_title_box_title_d">睡眠的症状</div>
            </div>
        </div>
        <div class="mafld">
            NAFLD 通常不会引起症状,如果脂肪堆积导致肝功能异常即出现症状,可能包括
        </div>
        <div class="content20">
            <div class="content20_center">
                <img class="imgs"
                    src="https://image.giantgocloud.com/www//ImageMapping/image/20231226/8BD7EE553A04493E98E03DA982BCEF00.png"
                    alt="">
                <div class="content20_center_left">
                    <div class="content20_center_left_list" :style="{ left: `-100px`, top: `-40px` }">
                        <div class="content20_center_left_lsit_text">皮肤发黄、眼白 部分也发黄 (黄痘症)</div>
                        <div class="content20_center_left_lsit_num">01</div>
                    </div>
                    <div class="content20_center_left_list" :style="{ left: `-120px`, top: `-100px` }">
                        <div class="content20_center_left_lsit_text">食欲不振、 肌肉无力</div>
                        <div class="content20_center_left_lsit_num">02</div>
                    </div>
                    <div class="content20_center_left_list" :style="{ left: `-140px`, top: `-160px` }">
                        <div class="content20_center_left_lsit_text">食欲不振、 肌肉无力</div>
                        <div class="content20_center_left_lsit_num">03</div>
                    </div>
                </div>
                <div class="content20_center_right">
                    <div class="content20_center_right_list" :style="{ left: `220px`, top: `-40px` }">
                        <div class="content20_center_right_lsit_num">04</div>
                        <div class="content20_center_right_lsit_text">肝肿大</div>
                    </div>
                    <div class="content20_center_right_list" :style="{ left: `260px`, top: `-100px` }">
                        <div class="content20_center_right_lsit_num">05</div>
                        <div class="content20_center_right_lsit_text">肝肿大</div>
                    </div>
                    <div class="content20_center_right_list" :style="{ left: `260px`, top: `-160px` }">
                        <div class="content20_center_right_lsit_num">06</div>
                        <div class="content20_center_right_lsit_text">肝肿大</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mafld_d">
            NAFLD应尽快治疗,否则可能进展为肝硬化。
        </div>
    </div>
</template>

<script>
import Head from "./head.vue"
export default {
    components: {
        Head,
    },
    data() {
        return {

        }
    }
}
</script>

<style lang="scss">
.testReport_pages9 {
    width: 595px;
    height: 842px;
    padding: 0 60px 0 53px;
    position: relative;

    .testReport_pages4_title_box {
        position: relative;
        width: 90px;
        height: 140px;
        border: 3px solid #DFE3E8;
        margin-top: 48px;
        margin-left: 0px;

        .testReport_pages4_title_box_serialNumber {
            font-size: 55px;
            font-weight: 400;
            color: #DFE3E8;
            padding-top: 70px;
            padding-left: 10px;
        }

        .testReport_pages4_title_box_title {
            position: absolute;
            left: 20px;
            top: 28px;
            width: 200px;
            background-color: #fff;
            color: #7DC580;
            font-size: 24px;
        }
    }

    .mafld {
        font-size: 12px;
        color: #474F57;
        padding-top: 30px;
    }

    .mafld_d {
        font-size: 12px;
        color: #474F57;
        padding-top: 30px;
    }
}

.content20 {
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;

    .content20_center {
        width: 250px;
        height: 244px;
        margin: 0 auto;

        .imgs {
            width: 100%;
            height: 100%;
        }

        .content20_center_left {
            position: relative;

            .content20_center_left_list {
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 120px;

                .content20_center_left_lsit_text {
                    flex: 1;
                    font-size: 12px;
                    padding-right: 10px;
                }

                .content20_center_left_lsit_num {
                    width: 30px;
                    height: 30px;
                    border: 3px solid #ECF7ED;
                    text-align: center;
                    line-height: 30px;
                    color: #7DC580;
                    border-radius: 50%;
                }
            }
        }

        .content20_center_right {
            position: relative;
            .content20_center_right_list {
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 120px;
                .content20_center_right_lsit_text {
                    flex: 1;
                    font-size: 12px;
                    padding-right: 10px;
                }

                .content20_center_right_lsit_num {
                    width: 30px;
                    height: 30px;
                    border: 3px solid #ECF7ED;
                    text-align: center;
                    line-height: 30px;
                    color: #7DC580;
                    border-radius: 50%;
                }
            }
        }
    }
}
</style>
