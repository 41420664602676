<template>
    <div class="testReport_pages11">
        <div class="testReport_pages4_title_box">
            <div class="testReport_pages4_title_box_serialNumber">
                01
            </div>
            <div class="testReport_pages4_title_box_title">
                <div class="testReport_pages4_title_box_title_d">脂肪肝的定义</div>
            </div>
        </div>
        <div class="content">
            非酒精性脂肪肝(NAFLD)是一种与肥胖相关的慢性进展性肝脏疾病，通常是指不饮酒的人的脂肪堆积在肝脏中形成的一种疾病NAFLD
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    }
}
</script>

<style lang="scss">
.testReport_pages11 {
    width: 595px;
    height: 842px;
    padding: 0 60px 0 53px;
    position: relative;

    .testReport_pages4_title_box {
        position: relative;
        width: 90px;
        height: 140px;
        border: 3px solid #DFE3E8;
        margin-top: 48px;
        margin-left: 0px;

        .testReport_pages4_title_box_serialNumber {
            font-size: 55px;
            font-weight: 400;
            color: #DFE3E8;
            padding-top: 70px;
            padding-left: 10px;
        }

        .testReport_pages4_title_box_title {
            position: absolute;
            left: 20px;
            top: 28px;
            width: 200px;
            background-color: #fff;
            color: #7DC580;
            font-size: 24px;
        }
    }

}

.content {
    width: 100%;
    height: 300px;
    background: url('https://image.giantgocloud.com/www/ImageMapping/image/20231228/4D7F047DFE1C4A7788F12C00E48DF1CD.png') no-repeat;
    background-size: 100% 100%;
    padding-left: 40px;
    padding-top: 60px;
}
</style>
