<template>
  <div class="testReport_pages13 A4height">

    <Head bgColor="#c5ead2"></Head>
    <div class="testReport_pages4_title_box">
      <div class="testReport_pages4_title_box_serialNumber">
        03
      </div>
      <div class="testReport_pages4_title_box_title">
        <div class="testReport_pages4_title_box_title_p">睡眠的</div>
        <div class="testReport_pages4_title_box_title_d">风险因素</div>
      </div>
    </div>
    <div class="testReport_pages13_content">
      <div class="testReport_pages13_content_bg">
        <img class="wh100"
          src="https://image.giantgocloud.com/www//ImageMapping/image/20231212/5BDE7FB33A6E4F059CB06C9E18DE3D91.png"
          alt="" />
      </div>
      <div style="position: relative;">
        <div class="testReport_pages13_content_text">
          脂肪肝的风险因素
        </div>
        <div class="testReport_pages13_content_text1">
          NAFLD在男性中更易发生,并且随着年龄的增长而增加。
        </div>
        <div class="testReport_pages13_content_ul">
          <div class="testReport_pages13_content_li">
            <div class='testReport_pages13_content_li_title a_c'>
              其他可增加NAFLD几率的因素包括：
            </div>
            <div class="testReport_pages13_content_li_li">
              <div class="testReport_pages13_content_li_li_left"></div>
              <div class="testReport_pages13_content_li_li_text">
                各种甜饮料,均含工业果糖浆
              </div>
            </div>
            <div class="testReport_pages13_content_li_li">
              <div class="testReport_pages13_content_li_li_left"></div>
              <div class="testReport_pages13_content_li_li_text">
                各种甜饮料,均含工业果糖浆
              </div>
            </div>
            <div class="testReport_pages13_content_li_li">
              <div class="testReport_pages13_content_li_li_left"></div>
              <div class="testReport_pages13_content_li_li_text">
                各种甜饮料,均含工业果糖浆
              </div>
            </div>
          </div>
          <div class="testReport_pages13_content_li">
            <div class='testReport_pages13_content_li_title a_c'>
              其他可增加NAFLD几率的因素包括：
            </div>
            <div class="testReport_pages13_content_li_li">
              <div class="testReport_pages13_content_li_li_left"></div>
              <div class="testReport_pages13_content_li_li_text">
                各种甜饮料,均含工业果糖浆
              </div>
            </div>
            <div class="testReport_pages13_content_li_li">
              <div class="testReport_pages13_content_li_li_left"></div>
              <div class="testReport_pages13_content_li_li_text">
                各种甜饮料,均含工业果糖浆
              </div>
            </div>
            <div class="testReport_pages13_content_li_li">
              <div class="testReport_pages13_content_li_li_left"></div>
              <div class="testReport_pages13_content_li_li_text">
                各种甜饮料,均含工业果糖浆
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import Head from "./head.vue"
export default {
  data() {
    return {
    }
  },
  methods: {
    hexToRgb(hex, opt) {
      // Remove "#" symbol from the beginning of the string
      hex = hex.replace("#", "");
      // Convert each pair of characters to decimal values for red, green, and blue
      var red = parseInt(hex[0] + hex[1], 16);
      var green = parseInt(hex[2] + hex[3], 16);
      var blue = parseInt(hex[4] + hex[5], 16);
      return 'rgba(' + red + ',' + green + ',' + blue + ',' + opt + ')';
    }
  },
  mounted() {

  },
  components: {
    Head,
  }
}
</script>

<style lang="scss" scoped>
.testReport_pages13 {
  background-color: #fff;

  .testReport_pages4_title_box {
    position: relative;
    width: 90px;
    height: 140px;
    border: 3px solid #DFE3E8;
    margin-top: 48px;
    margin-left: 50px;

    .testReport_pages4_title_box_serialNumber {
      font-size: 55px;
      font-weight: 400;
      color: #DFE3E8;
      padding-top: 70px;
      padding-left: 10px;
    }

    .testReport_pages4_title_box_title {
      position: absolute;
      left: 20px;
      top: 14px;
      width: 200px;
      background-color: #fff;
      color: #7DC580;
      font-size: 24px;
    }
  }

  .testReport_pages13_content {
    overflow: hidden;
    margin-top: 64px;
    margin-left: 113px;
    position: relative;
    width: 390px;
    height: 684px;

    .testReport_pages13_content_bg {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    .testReport_pages13_content_text {
      margin-left: 93px;
      margin-top: 21px;
      font-size: 21px;
      font-weight: 500;
      color: #7DC580;
    }

    .testReport_pages13_content_text1 {
      margin-left: 93px;
      margin-top: 45px;
      font-size: 12px;
      font-weight: 400;
      color: #474F57;
    }

    .testReport_pages13_content_ul {
      margin-top: 66px;

      .testReport_pages13_content_li {
        margin-bottom: 50px;

        .testReport_pages13_content_li_title {
          margin-left: 81px;
          width: 289px;
          height: 25px;
          background: #7DC580;
          border-radius: 13px;
          font-size: 14px;
          font-weight: 500;
          color: #FFFFFF;
          padding-left: 12px;
        }
      }

      .testReport_pages13_content_li_li {
        display: flex;
        align-items: center;
        margin-top: 30px;

        .testReport_pages13_content_li_li_left {
          margin-right: 30px;
          margin-left: 51px;
          width: 11px;
          height: 11px;
          background: #FFFFFF;
          border: 1px solid #7DC580;
          border-radius: 50%;
        }

        .testReport_pages13_content_li_li_text {
          font-size: 12px;
          font-weight: 400;
          color: #474F57;
        }
      }
    }
  }
}</style>
