<template>
  <div class="testReport_pages15 A4height">

    <Head bgColor="#BCEDEC"></Head>
    <div class="testReport_pages4_title_box">
      <div class="testReport_pages4_title_box_serialNumber">
        05
      </div>
      <div class="testReport_pages4_title_box_title">
        <div class="testReport_pages4_title_box_title_p">胃</div>
        <div class="testReport_pages4_title_box_title_d">功能分析</div>
      </div>
    </div>
    <div class="contents">
      <div class="left">
        <div class="left_title">
          <div class="left_title_img">
            <img class="imgs" src="" alt="">
          </div>
          <div class="left_title_pross">
            <div class="left_title_pross_text">
              {{ PagesInitData.MainOrgan.Name }}
            </div>
            <div class="left_title_pross_w"></div>
          </div>
        </div>
        <div class="left_title_text">
          {{ PagesInitData.MainOrgan.Content }}
        </div>
        <div class="left_title_intestine">
          <img class="imgs" src="https://image.giantgocloud.com/www//ImageMapping/image/20231226/38AB5BAFBBC344E9931E0F1E4C982B12.png" alt="">
        </div>
      </div>
      <div class="rgight">
        <img class="imgs"
          src="https://image.giantgocloud.com/www//ImageMapping/image/20231226/956001B07CC24310B3C08E15E1FC6B09.png"
          alt="">
      </div>
    </div>
  </div>
</template>

<script>
import Head from "./head.vue"
// import Head from '/View/reportPdf/testReport/head.vue'
export default {
  data() {
    return {
      obj1:''
    }
  },
  methods: {

  },
  mounted() {
    console.log("999")
    console.log(this.PagesInitData.MainOrgan,'obj1111111111')
  },
  components: {
    Head,
  },
  props: ['PagesInitData'],
  watch: {
    PagesInitData: {
        deep: true, // 开启深度监听 专门对付复杂数据类型
        handler: function(newValueA, oldValue) {
          console.log(newValueA,oldValue)
         
          
        }
      }
    }
}
</script>

<style lang="scss" scoped>
.testReport_pages15 {
  background-color: #fff;
  width: 595px;
    height: 842px;
    // padding: 0 60px 0 53px;
    position: relative;
  .testReport_pages4_title_box {
    position: relative;
    width: 90px;
    height: 140px;
    border: 3px solid #DFE3E8;
    margin-top: 48px;
    margin-left: 50px;

    .testReport_pages4_title_box_serialNumber {
      font-size: 55px;
      font-weight: 400;
      color: #DFE3E8;
      padding-top: 70px;
      padding-left: 10px;
    }

    .testReport_pages4_title_box_title {
      position: absolute;
      left: 20px;
      top: 14px;
      width: 200px;
      background-color: #fff;
      color: #2BD8D8;
      font-size: 24px;
    }
  }

  .contents {
    display: flex;
    justify-content: space-between;
    padding-top: 60px;

    .left {
      flex: 1;
      margin-left: 60px;

      .left_title {
        display: flex;
        align-items: center;

        .left_title_img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          border: 2px solid #CB8716;

          .imgs {
            width: 100%;
            height: 100%;
          }
        }

        .left_title_pross {
          .left_title_pross_text {
            font-size: 14px;
            color: #474F57;
            padding-left: 8px;
          }

          .left_title_pross_w {
            background-color: #CB8716;
            width: 100px;
            height: 24px;
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
          }
        }
      }

      .left_title_text {
        color: #474F57;
        font-size: 12px;
        padding-left: 30px;
        padding-top: 10px;
      }

      .left_title_intestine {
        margin-top: 40px;
        margin-left: 60px;
        width: 220px;
        height: 220px;
        .imgs {
          width: 100%;
          height: 100%;
        }
      }

    }

    .rgight {
      width: 208px;
      min-height: 400px;

      .imgs {
        width: 100%;
        height: 100%;
      }
    }
  }
}</style>
