<template>
    <div class="testReport_pages4 A4height">

        <Head :bgColor="`#C8EBED`"></Head>
        <div class="testReport_pages4_title_box">
            <div class="testReport_pages4_title_box_serialNumber">
                01
            </div>
            <div class="testReport_pages4_title_box_title">
               <div class="testReport_pages4_title_box_title_p">睡眠&肝脏</div>
               <div class="testReport_pages4_title_box_title_d">健康分析矩阵图</div>
            </div>
        </div>
        <div style="width: 100%;">
            <div v-if="objectData" class="HealthAnalysisMatrix_info">
                <div class="HealthAnalysisMatrix_info_left">
                    <div v-for="(item, index) in objectData.leftList" :key="index"
                        :style="'color:' + item.color + ';border:1px solid ' + item.color + ';background:' + hexToRgb(item.color, 0.1)"
                        class="HealthAnalysisMatrix_info_left_li a_flex">
                        {{ item.title }}
                    </div>
                </div>
                <div class="HealthAnalysisMatrix_info_center">
                    <div class="HealthAnalysisMatrix_info_center_box">
                        <div class="HealthAnalysisMatrix_info_center_box_img a_flex">
                            <img class="wh100"
                                src="https://image.giantgocloud.com/www//ImageMapping/image/20231211/9F5BF718781C42C8A24D63FD871C2702.png"/>
                        </div>
                        <div class="HealthAnalysisMatrix_info_center_box_left_center">
                            <div v-if="objectData.rightList.length > 1" v-for="(item, index) in objectData.rightList"
                                :key="index" class="HealthAnalysisMatrix_info_center_box_left_center_radio a_flex">
                                <div v-if="index == 0" style="display: flex;flex-direction: column;border-radius: 50%;"
                                    class="wh100 a_flex HealthAnalysisMatrix_info_center_box_left_center_radio_box"
                                    :style="'color:' + item.color + ';border:1px solid ' + item.color + ';background:' + hexToRgb(item.color, 0.1) + ';'">
                                    <div class="HealthAnalysisMatrix_info_center_box_left_center_radio_box_leve">
                                        {{ item.leve }}
                                    </div>
                                    <div class="HealthAnalysisMatrix_info_center_box_left_center_radio_box_title">
                                        {{ item.title }}
                                    </div>
                                </div>
                                <div v-if="index == 1" style="display: flex;flex-direction: column;border-radius: 50%;"
                                    class="wh100 a_flex HealthAnalysisMatrix_info_center_box_left_center_radio_box"
                                    :style="'color:' + item.color + ';border:1px solid ' + item.color + ';background:' + hexToRgb(item.color, 0.1) + ';'">
                                    <div class="HealthAnalysisMatrix_info_center_box_left_center_radio_box_title">
                                        {{ item.title }}
                                    </div>
                                    <div class="HealthAnalysisMatrix_info_center_box_left_center_radio_box_leve">
                                        {{ item.leve }}
                                    </div>
                                </div>
                            </div>
                            <div v-if="objectData.rightList.length == 1" v-for="(item, index) in objectData.rightList"
                                :key="index" class="HealthAnalysisMatrix_info_center_box_left_center_radio a_flex">
                                <div v-if="index == 0" style="display: flex;flex-direction: column;border-radius: 50%;"
                                    class="wh100 a_flex HealthAnalysisMatrix_info_center_box_left_center_radio_box"
                                    :style="'color:' + item.color + ';border:1px solid ' + item.color + ';background:' + hexToRgb(item.color, 0.1) + ';'">
                                    <div class="HealthAnalysisMatrix_info_center_box_left_center_radio_box_title">
                                        {{ item.title }}
                                    </div>
                                    <div class="HealthAnalysisMatrix_info_center_box_left_center_radio_box_leve">
                                        {{ item.leve }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="HealthAnalysisMatrix_info_center_box_img a_flex">
                            <img class="wh100"
                                src="https://image.giantgocloud.com/www//ImageMapping/image/20231211/7B376D9C9F7E4615A5CC2CB02039A0E9.png"/>
                        </div>
                    </div>
                </div>
                <div class="HealthAnalysisMatrix_info_right">
                    <div v-if="objectData.rightList.length == 1" class="HealthAnalysisMatrix_info_right_title_box"
                        style="justify-content: center;">
                        <div v-for="(item, index) in objectData.rightList" :key="index" :style="'color:' + item.color"
                            class="HealthAnalysisMatrix_info_right_title">
                            <div class="HealthAnalysisMatrix_info_right_title_li">
                                {{ item.title }}
                            </div>
                            <div v-if="item.leve" class="HealthAnalysisMatrix_info_right_title_leve">
                                {{ item.leve }}
                            </div>
                        </div>
                    </div>
                    <div v-if="objectData.rightList.length > 1" class="HealthAnalysisMatrix_info_right_title_box">
                        <div v-for="(item, index) in objectData.rightList" :key="index" :style="'color:' + item.color"
                            class="HealthAnalysisMatrix_info_right_title">
                            <div class="HealthAnalysisMatrix_info_right_title_li">
                                {{ item.title }}
                            </div>
                            <div v-if="item.leve" class="HealthAnalysisMatrix_info_right_title_leve">
                                {{ item.leve }}
                            </div>
                        </div>
                    </div>
                    <div class="HealthAnalysisMatrix_info_right_content">
                        <div v-for="(item, index) in objectData.rightListA"
                            :style="item.type == 3 ? 'width:140px;' : item.type == 1 ? 'justify-content: flex-start;margin-right:18px;' : 'justify-content: flex-end;float:right;'"
                            class="HealthAnalysisMatrix_info_right_content_li a_flex">
                            <div v-if="item.type == 3" class="HealthAnalysisMatrix_info_right_content_liA"
                                :style="'color:' + item.color + ';border:1px solid ' + item.color + ';background:' + hexToRgb(item.color, 0.1) + ';width:100%;'">
                                <div class="HealthAnalysisMatrix_info_right_content_liA_title">
                                    {{ item.name }}
                                </div>
                                <div class="HealthAnalysisMatrix_info_right_content_liA_title_leve">
                                    {{ item.leve }}
                                </div>
                            </div>
                            <div v-if="item.type != 3" class="HealthAnalysisMatrix_info_right_content_liA"
                                :style="'color:' + item.color + ';border:1px solid ' + item.color + ';background:' + hexToRgb(item.color, 0.1) + ';width:61px;'">
                                <div class="HealthAnalysisMatrix_info_right_content_liA_title">
                                    {{ item.name }}
                                </div>
                                <div class="HealthAnalysisMatrix_info_right_content_liA_title_leve">
                                    {{ item.leve }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="HealthAnalysisMatrix_bottom">
                <div class="HealthAnalysisMatrix_bottom_title">
                    {{ objectData.content.title }}
                </div>
                <div v-for="(item, index) in objectData.content.list" :key="index" class="HealthAnalysisMatrix_bottom_list">
                    <span v-if="item.leve" class="HealthAnalysisMatrix_bottom_list_leve a_flex"
                        :style="'border:1px solid ' + item.color + ';color:' + item.color + ';background:' + hexToRgb(item.color, 0.1)">{{
                            item.leve }}</span>
                    <span class="HealthAnalysisMatrix_bottom_list_title" :style="'color:' + item.color">{{ item.title
                    }}:</span>
                    <span class="HealthAnalysisMatrix_bottom_list_text">{{ item.text }}</span>
                </div>
            </div>
            <div class="pages4_bottom">
                <div class="pages4_bottom_li">
                    <div class="pages4_bottom_li_left a_flex" style='color:#10854A;border:1px solid #10854A;'
                        :style="'background:' + hexToRgb('#10854A', 0.1)">
                        A
                    </div>
                    <div class="pages4_bottom_li_right" style='color:#10854A;'>
                        最佳
                    </div>
                </div>

                <div class="pages4_bottom_li">
                    <div class="pages4_bottom_li_left a_flex" style='color:#5EC692;border:1px solid #5EC692;'
                        :style="'background:' + hexToRgb('#5EC692', 0.1)">
                        B
                    </div>
                    <div class="pages4_bottom_li_right" style='color:#5EC692;'>
                        正常
                    </div>
                </div>

                <div class="pages4_bottom_li">
                    <div class="pages4_bottom_li_left a_flex" style='color:#EEC122;border:1px solid #EEC122;'
                        :style="'background:' + hexToRgb('#EEC122', 0.1)">
                        C
                    </div>
                    <div class="pages4_bottom_li_right" style='color:#EEC122;'>
                        风险
                    </div>
                </div>

                <div class="pages4_bottom_li">
                    <div class="pages4_bottom_li_left a_flex" style='color:#D51931;border:1px solid #D51931;'
                        :style="'background:' + hexToRgb('#D51931', 0.1)">
                        D
                    </div>
                    <div class="pages4_bottom_li_right" style='color:#D51931;'>
                        疾病
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import Head from './head.vue'
export default {
  data () {
    return {
      ifZk: false,
      objectData: '',
      objectDataA: {
        content: {
          title: '影响您健康隐患的相关失衡因素解析',
          list: [{
            leve: '',
            title: '胰岛素抵抗',
            text: '非酒精性脂肪肝(NAFLD)是一种与肥胖相关的慢性进展性肝脏疾病，通常是指不饮酒的人的脂肪堆积在肝脏中形成的一种疾病。',
            color: '#D51931'
          },
          {
            leve: '',
            title: '胰岛素抵抗',
            text: '非酒精性脂肪肝(NAFLD)是一种与肥胖相关的慢性进展性肝脏疾病，通常是指不饮酒的人的脂肪堆积在肝脏中形成的一种疾病。',
            color: '#D51931'
          },
          {
            title: '胰岛素抵抗',
            text: '非酒精性脂肪肝(NAFLD)是一种与肥胖相关的慢性进展性肝脏疾病，通常是指不饮酒的人的脂肪堆积在肝脏中形成的一种疾病。',
            color: '#F9C716'
          },
          {
            title: '胰岛素抵抗',
            text: '非酒精性脂肪肝(NAFLD)是一种与肥胖相关的慢性进展性肝脏疾病，通常是指不饮酒的人的脂肪堆积在肝脏中形成的一种疾病。',
            color: '#F9C716'
          }
          ]
        },
        leftList: [{
          title: '饮食',
          color: '#F9C716'
        },
        {
          title: '压力',
          color: '#5EC692'
        },
        {
          title: '睡眠',
          color: '#5EC692'
        },
        {
          title: '运动',
          color: '#F9C716'
        }
        ],
        rightList: [{
          title: '脂肪肝',
          leve: '',
          color: '#D51931',
          list: [{
            name: '甲状腺',
            color: '#D51931',
            leve: ''
          },
          {
            name: '慢性肝炎',
            color: '#F9C716',
            leve: ''
          }
          ]
        },
        {
          title: '脂肪肝',
          leve: '',
          color: '#D51931',
          list: [{
            name: '甲状腺',
            color: '#D51931',
            leve: ''
          },
          {
            name: '肝炎',
            color: '#F9C716',
            leve: ''
          }
          ]
        }
        ]
      }
    }
  },
  methods: {
    hexToRgb (hex, opt) {
      // Remove "#" symbol from the beginning of the string
      hex = hex.replace('#', '')
      // Convert each pair of characters to decimal values for red, green, and blue
      var red = parseInt(hex[0] + hex[1], 16)
      var green = parseInt(hex[2] + hex[3], 16)
      var blue = parseInt(hex[4] + hex[5], 16)
      return 'rgba(' + red + ',' + green + ',' + blue + ',' + opt + ')'
    }
  },
  mounted () {
    const newArr = []
    if (this.objectDataA.rightList.length > 1) {
      for (let i = 0; i < this.objectDataA.rightList[0].list.length; i++) {
        let ifT = false
        for (let q = 0; q < this.objectDataA.rightList[1].list.length; q++) {
          if (this.objectDataA.rightList[1].list[q].name == this.objectDataA.rightList[0].list[i].name) {
            console.log('this.objectDataA.rightList[1].list[q]', this.objectDataA.rightList[1].list[q])
            ifT = true
          }
        }
        if (ifT) {
          const a = this.objectDataA.rightList[0].list[i]
          a.type = 3
          newArr.push(a)
        } else {
          const a = this.objectDataA.rightList[0].list[i]
          a.type = 1
          newArr.push(a)
        }
      }
      for (let i = 0; i < this.objectDataA.rightList[1].list.length; i++) {
        let ifT = false
        for (let q = 0; q < newArr.length; q++) {
          if (this.objectDataA.rightList[1].list[i].name == newArr[q].name) {
            ifT = true
          }
        }
        if (!ifT) {
          const a = this.objectDataA.rightList[1].list[i]
          a.type = 2
          newArr.push(a)
        }
      }
    } else {
      for (let i = 0; i < this.objectDataA.rightList[0].list.length; i++) {
        const a = this.objectDataA.rightList[0].list[i]
        a.type = 3
        newArr.push(a)
      }
    }
    console.log('newArr', newArr)
    for (let i = 0; i < newArr.length; i++) {
      for (let q = i + 1; q < newArr.length; q++) {
        if (newArr[i].type < newArr[q].type) {
          let a
          a = newArr[q]
          newArr[q] = newArr[i]
          newArr[i] = a
        }
      }
    }
    console.log('newArr', newArr)
    const newArrlist = []
    let ifTypeLength = newArr.length - 1
    let ifType = false
    for (let i = 0; i < newArr.length; i++) {
      if (newArrlist.length < newArr.length) {
        if (newArr[i].type == 3) {
          newArrlist.push(newArr[i])
        } else {
          if (!ifType) {
            ifType = true
            newArrlist.push(newArr[i])
          } else {
            ifType = false
            newArrlist.push(newArr[ifTypeLength])
            ifTypeLength--
            i--
          }
        }
      }
    }

    this.objectDataA.rightListA = newArrlist
    this.objectData = this.objectDataA
  },
  components: {
    Head
  }
}
</script>

<style lang="scss" scoped>
.testReport_pages4 {
    background-color: #fff;

    .testReport_pages4_title_box {
        position: relative;
        width: 90px;
        height: 140px;
        border: 3px solid #DFE3E8;
        margin-top: 48px;
        margin-left: 50px;
        .testReport_pages4_title_box_serialNumber {
            font-size: 55px;
            font-weight: 400;
            color: #DFE3E8;
            padding-top: 70px;
            padding-left: 10px;
        }
        .testReport_pages4_title_box_title {
            position: absolute;
            left: 20px;
            top: 14px;
            width: 200px;
            background-color: #fff;
            color: #20A7B2;
            font-size: 24px;
        }
    }
}

.HealthAnalysisMatrix_info {
    width: 100%;
    padding: 0 101px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;

    .HealthAnalysisMatrix_info_left {
        margin-top: 60px;
        display: flex;
        flex-direction: column;

        .HealthAnalysisMatrix_info_left_li {
            margin-bottom: 13px;
            box-sizing: border-box;
            width: 60px;
            height: 32px;
            border-radius: 6px;
        }
    }

    .HealthAnalysisMatrix_info_center {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;

        .HealthAnalysisMatrix_info_center_box {
            position: relative;
            top: 30px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .HealthAnalysisMatrix_info_center_box_img {
                width: 13px;
                height: 25px;
            }

            .HealthAnalysisMatrix_info_center_box_left_center {
                display: flex;
                flex-direction: column;

                .HealthAnalysisMatrix_info_center_box_left_center_radio {
                    border-radius: 50%;
                    display: flex;
                    flex-direction: column;
                    box-sizing: border-box;
                    width: 84px;
                    height: 84px;

                    .HealthAnalysisMatrix_info_center_box_left_center_radio_box {
                        .HealthAnalysisMatrix_info_center_box_left_center_radio_box_title {
                            font-size: 18px;
                        }

                        .HealthAnalysisMatrix_info_center_box_left_center_radio_box_leve {
                            font-size: 23px;
                        }
                    }
                }

                .HealthAnalysisMatrix_info_center_box_left_center_radio:nth-of-type(1) {
                    position: relative;
                    top: 7px;
                }

                .HealthAnalysisMatrix_info_center_box_left_center_radio:nth-of-type(2) {
                    position: relative;
                    top: -7px;
                }
            }
        }
    }

    .HealthAnalysisMatrix_info_right {
        width: 140px;

        .HealthAnalysisMatrix_info_right_title_box {
            display: flex;
            justify-content: space-between;

            .HealthAnalysisMatrix_info_right_title {
                display: flex;
                flex-direction: column;
                align-items: center;

                .HealthAnalysisMatrix_info_right_title_li {
                    font-size: 18px;
                }

                .HealthAnalysisMatrix_info_right_title_leve {
                    font-size: 18px;
                }
            }
        }

        .HealthAnalysisMatrix_info_right_content {

            // display: flex;
            // flex-direction: column;
            .HealthAnalysisMatrix_info_right_content_li {
                display: flex;
                width: 140px;
                box-sizing: border-box;
                padding: 5px 0;
                float: left;
                width: auto;

                .HealthAnalysisMatrix_info_right_content_liA {
                    border-radius: 6px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    min-height: 30px;
                    .HealthAnalysisMatrix_info_right_content_liA_title {
                        line-height: 30px;
                        font-size: 13px;
                        font-weight: 500;
                    }

                    .HealthAnalysisMatrix_info_right_content_liA_title_leve {
                        font-size: 17px;
                    }
                }
            }
        }
    }

}

.HealthAnalysisMatrix_bottom {
    margin-top: 18px;
    padding: 0 70px;

    .HealthAnalysisMatrix_bottom_title {
        margin-bottom: 23px;
        font-size: 14px;
        color: #4A9AAF;
    }

    .HealthAnalysisMatrix_bottom_list {
        font-size: 10px;
        color: #474F57;
        margin-bottom: 15px;
        line-height: 15px;

        .HealthAnalysisMatrix_bottom_list_leve {
            display: inline-flex;
            flex-shrink: 0;
            width: 16px;
            height: 14px;
            border-radius: 3px;
            margin-right: 5px;
            opacity: 1;
        }

        .HealthAnalysisMatrix_bottom_list_title {
            margin-right: 5px;
        }

        .HealthAnalysisMatrix_bottom_list_text {}

        .HealthAnalysisMatrix_bottom_list_h {
            margin-top: 30px;
            width: 622px;
            height: 0px;
            border-radius: 0px 0px 0px 0px;
            opacity: 1;
            border: 2px solid;
            border-image: linear-gradient(270deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 2 2;
        }
    }
}

.pages4_bottom {
    display: flex;
    margin-top: 25px;
    padding: 0 70px;

    .pages4_bottom_li {
        display: flex;
        align-items: center;
        margin-right: 14px;

        .pages4_bottom_li_left {
            font-size: 11px;
            margin-right: 6px;
            width: 24px;
            height: 13px;
            border-radius: 2px;
        }

        .pages4_bottom_li_right {
            font-size: 11px;
        }
    }
}</style>
