<template>
  <div class="testReport_pages12 A4height">

    <Head bgColor="#c5ead2"></Head>
    <div class="testReport_pages4_title_box">
      <div class="testReport_pages4_title_box_serialNumber">
        02
      </div>
      <div class="testReport_pages4_title_box_title">
        <div class="testReport_pages4_title_box_title_d">脂肪肝的定义</div>
      </div>
    </div>
    <div class="testReport_pages12_content" style="width: 100%;">
      <div class="testReport_pages12_content_title">
        当肝脏不能分解脂肪时，它们就在肝组织中堆积起来形成脂肪肝。
      </div>
      <div class="testReport_pages12_contentA">
        <div class="testReport_pages12_content_left">
          <div class="testReport_pages12_content_left_li">
            <div class="testReport_pages12_content_left_li_left a_flex">
              01
            </div>
            <div class="testReport_pages12_content_left_li_right">
              <div class="testReport_pages12_content_left_li_right_title">
                胃酸和消化液的失衡可导致溃疡，这可能是由于：
              </div>
              <div class="testReport_pages12_content_left_li_right_text">
                许多研究表明,果糖促进肝脏中新脂肪分子形成，阻断现有脂肪分解，刺激自由基生成，引起胰岛素抵抗。越来越多的肥胖患者，与长期消费各
              </div>
            </div>
          </div>
          <div class="testReport_pages12_content_left_li">
            <div class="testReport_pages12_content_left_li_left a_flex">
              02
            </div>
            <div class="testReport_pages12_content_left_li_right">
              <div class="testReport_pages12_content_left_li_right_title">
                胃酸和消化液的失衡可导致溃疡，这可能是由于：
              </div>
              <div class="testReport_pages12_content_left_li_right_text">
                <div>
                  许多研究表明,果糖促进肝脏中新脂肪分子形成，阻断现有脂肪分解，刺激自由基生成，引起胰岛素抵抗。越来越多的肥胖患者，与长期消费各;
                  许多研究表明,果糖促进肝脏中新脂肪分子形成，阻断现有脂肪分解，刺激自由基生成，引起胰岛素抵抗。越来越多的肥胖患者，与长期消费各
                  许多研究表明,果糖促进肝脏中新脂肪分子形成，阻断现有脂肪分解，刺激自由基生成，引起胰岛素抵抗。越来越多的肥胖患者，与长期消费各
                </div>
              </div>
            </div>
          </div>
          <div class="testReport_pages12_content_left_li">
            <div class="testReport_pages12_content_left_li_left a_flex">
              03
            </div>
            <div class="testReport_pages12_content_left_li_right">
              <div class="testReport_pages12_content_left_li_right_title">
                胃酸和消化液的失衡可导致溃疡，这可能是由于：
              </div>
              <div class="testReport_pages12_content_left_li_right_text">
                <div>
                  许多研究表明,果糖促进肝脏中新脂肪分子形成，阻断现有脂肪分解，刺激自由基生成，引起胰岛素抵抗。越来越多的肥胖患者，与长期消费各
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="testReport_pages12_content_right">
          <div class="testReport_pages12_content_right_img">
            <img class="wh100"
              src="https://image.giantgocloud.com/www//ImageMapping/image/20231212/22F43740C06A47F6AEE4713396065447.png"
              alt="" />
          </div>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
import Head from "./head.vue"
export default {
  data() {
    return {

    }
  },
  methods: {
    hexToRgb(hex, opt) {
      // Remove "#" symbol from the beginning of the string
      hex = hex.replace("#", "");
      // Convert each pair of characters to decimal values for red, green, and blue
      var red = parseInt(hex[0] + hex[1], 16);
      var green = parseInt(hex[2] + hex[3], 16);
      var blue = parseInt(hex[4] + hex[5], 16);
      return 'rgba(' + red + ',' + green + ',' + blue + ',' + opt + ')';
    }
  },
  mounted() {

  },
  components: {
    Head,
  }
}
</script>

<style lang="scss" scoped>
.testReport_pages12 {
  background-color: #fff;

  .testReport_pages4_title_box {
    position: relative;
    width: 90px;
    height: 140px;
    border: 3px solid #DFE3E8;
    margin-top: 48px;
    margin-left: 70px;

    .testReport_pages4_title_box_serialNumber {
      font-size: 55px;
      font-weight: 400;
      color: #DFE3E8;
      padding-top: 70px;
      padding-left: 10px;
    }

    .testReport_pages4_title_box_title {
      position: absolute;
      left: 20px;
      top: 28px;
      width: 200px;
      background-color: #fff;
      color: #7DC580;
      font-size: 24px;
    }
  }

  .testReport_pages12_content {
    margin-top: 34px;
    padding-left: 70px;

    .testReport_pages12_content_title {
      font-size: 12px;
      font-weight: 400;
      color: #474F57;
    }

    .testReport_pages12_contentA {
      margin-top: 30px;
      display: flex;
      justify-content: space-between;

      .testReport_pages12_content_left {
        .testReport_pages12_content_left_li {
          display: flex;
          margin-bottom: 25px;

          .testReport_pages12_content_left_li_left {
            display: flex;
            flex-shrink: 0;
            width: 39px;
            height: 39px;
            background: #7DC580;
            border-radius: 50%;
            font-size: 21px;
            font-weight: 400;
            color: #FFFFFF;
            margin-right: 20px;
          }

          .testReport_pages12_content_left_li_right {
            width: 280px;

            .testReport_pages12_content_left_li_right_title {
              font-size: 12px;
              font-weight: 400;
              color: #7DC580;
            }

            .testReport_pages12_content_left_li_right_text {
              margin-top: 9px;
              font-size: 20px;
              zoom: 0.5;
              font-weight: 400;
              color: #474F57;
            }
          }
        }

        .testReport_pages12_content_left_li:nth-last-of-type(1) {
          margin-bottom: 0;
        }
      }

      .testReport_pages12_content_right {
        position: relative;
        width: 119px;
        margin-right: 20px;

        .testReport_pages12_content_right_img {
          position: absolute;
          top: 50%;
          transform: translateY(-112px);
          height: 224px;
          width: 119px;
          right: 20px;
        }
      }
    }


  }
}</style>
